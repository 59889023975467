<template>
  <div id="idlogin" @keydown.enter="keyDownLogin">
    <!-- <h1>这是账号登录组件</h1> -->
    <el-breadcrumb separator="|">
      <el-breadcrumb-item :class="'active'">{{$t('m.loginandregister.Account_login')}}</el-breadcrumb-item>
      <!-- <el-breadcrumb-item @click="commitfather('PhoneLogin')">手机登录</el-breadcrumb-item> -->
    </el-breadcrumb>
    <div id="input">
      <!-- <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
      > -->
      <div id="idinput">
        <span class="name">{{$t('m.loginandregister.username')}}</span>
        <el-input v-model="inputvalue.idinput" :placeholder="$t('m.loginandregister.Please_enter_username')" />
      </div>
      <div id="pwdinput">
        <span class="name">{{$t('m.loginandregister.password')}}</span>
        <el-input maxlength="16"
         v-model="inputvalue.pwdinput" show-password :placeholder="$t('m.loginandregister.Please_enter_password')" />
        <!-- <i class="icon"></i> -->
      </div>
      <!-- </el-form> -->
      <div id="sub">
          <el-button style="color: #ffffff"
          native-type="submit"
          @click="submitLogin"
          >{{$t('m.loginandregister.Login_immediately')}}</el-button>
      </div>
    </div>
    <div id="toother" v-if="!this.$props.quickLogin">
      <span class="left"><a @click="commitfather('Register')">{{$t('m.loginandregister.free_registration')}}</a></span>
      <span class="right"><a @click="commitfather('RetrievePwd')">{{$t('m.loginandregister.forget_password')}}</a></span>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, reactive } from 'vue'
import LogRegMain from '../LogRegMain'
import { useRouter } from 'vue-router'

import http from '../../../api/http.js'
import CK from '../../../tools/cookie.js'
import tomessage from '../../../tools/tomessage.js'
import { ElMessage } from 'element-plus'
import { setStore } from '../../../tools/storage'

export default {
  name: 'IDLogin',
  data () {
    return {
    }
  },
  props: ['quickLogin'],
  methods: {
  },
  mounted () {
    // const keyDownLogin = () => {
    //   document.onkeydown = function () {
    //     const key = window.event.keyCode
    //     if (key === 13) {
    //       if (datcop.type.name === 'IDLogin') {
    //         console.log(datcop)
    //         submitLogin()
    //         console.log(datcop.type.name)
    //       }
    //     }
    //   }
    // }
  },
  beforeUnmount () {
    // console.log(this)
  },
  setup (props, context) {
    const router = useRouter()

    const datcop = getCurrentInstance()

    const inputvalue = reactive({
      idinput: '',
      pwdinput: ''
    })
    const commitfather = (pageName) => {
      context.emit('topage', pageName)
    }
    const verify = (value, type) => {
      switch (type) {
        case 'user':
          LogRegMain.setmessage.User(value)
          break
        case 'phone':
          LogRegMain.setmessage.Phone(value)
          break
        case 'password':
          LogRegMain.setmessage.Password(value)
          break
        default :
          return alert.error('找不到匹配内容!')
      }
    }
    const keyDownLogin = () => {
      document.onkeydown = function () {
        const key = window.event.keyCode
        if (key === 13) {
          if (datcop.type.name === 'IDLogin') {
            // console.log(datcop)
            submitLogin()
            console.log(datcop.type.name)
          }
        }
      }
    }
    const submitLogin = () => {
      const pardata = {
        phone: inputvalue.idinput,
        password: inputvalue.pwdinput
      }
      // console.log(CK.getCookie('token'))
      if (tomessage.fromvalidate('Phone', pardata.phone).data.type === 'success') {
        http.post('/mall-portal/sso/login', { username: pardata.phone, password: pardata.password }, false)
          .then(res => {
            console.log(res)
            switch (res.data.code) {
              case 200:
                // ElMessage.success({ message: '登录成功！！' })
                CK.setCookie('token', res.data.data.token, 7)
                setStore('token', res.data.data.token)
                saveUser(res.data.data)
                break
              case 500:
                ElMessage.error({ message: res.data.msg })
                break
              default:
                break
            }
          }).catch(e => {
            // console.log(e)
          })
      } else {
        if (pardata.phone === '' || pardata.password === '') {
          ElMessage.error({ duration: 3000, showClose: true, message: '手机号或密码不能为空', offset: 150 })
        } else {
          ElMessage.error({ duration: 3000, showClose: true, message: '手机号不正确', offset: 150 })
        }
      }
    }
    const saveUser = (token) => {
      console.log(token)
      // console.log(CK)
      ElMessage({ duration: 1500, message: '登录成功！！', type: 'success' })
      // if (props) {

      // }
      console.log(props.quickLogin)
      if (props.quickLogin) { // 快捷登录
        // router.push('/')
        http.get('/mall-portal/sso/info')
          .then(res => {
            console.log(res)
            const User = {
              id: res.data.data.id,
              nickname: res.data.data.realName,
              avatar: res.data.data.icon
            }
            setStore('User', User)
            setStore('usermessage', res.data.data) // 存个人信息
            setTimeout(() => {
              window.location.reload()
            }, 200)
          }).catch(err => {
            console.log(err)
          })
      } else {
        router.push('/')
        // window.location.reload()
        setTimeout(() => {
          window.location.reload()
        }, 200)
      }
    }
    return { commitfather, verify, submitLogin, saveUser, keyDownLogin, inputvalue }
  }

}
</script>

<style lang="scss" scoped>
@import '../../../style/reglog.scss';
@import '../../../style/base.css';
#idlogin{
  // background: palegreen;
  height: 320px;
  box-sizing: border-box;
  margin: 40px;
  display: flex;
  flex-wrap: wrap;
  .el-breadcrumb{
    padding: 8px 40px;
    width: 100%;
    .el-breadcrumb__item{
      // font-size: 20px;
      .el-breadcrumb__inner{
        color: #999999;
        font-size: 20px;
        cursor: pointer;
      }
      .el-breadcrumb__separator{
        // display: block;
        margin:0 35px;
        font-size: 14px;
        font-weight: 500;
      }
    }
    .active{
      .el-breadcrumb__inner{
        color: #333333;
      }
    }
  }
  #input{
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
      #idinput,#pwdinput{
        margin: 15px 0;
        width: 100%;
        display: flex;
        border-bottom: 1px solid #EAEAEA;
        .name{
          height: 40px;
          line-height: 40px;
          min-width: 65px;
          font-size: 14px;
          flex-shrink: 0;
        }
        .el-input{
          .el-input__suffix{
            .el-input__suffix-inner{
              .el-input__icon{
                color: #333333;
              }
            }
          }
        }
        input{
          height: 30px;
          flex: 1;
        }
        .icon{
          border: 1px solid black;
          margin: 0 10px;
          width: 30px;
        }
      }
    #sub{
      width: 100%;
      margin: 30px 0;
      button{
        width: 100%;
        height: 40px;
        background: linear-gradient(0deg, #280E01 0%, #673000 100%);
        span{
          font-weight: 400;
        }
      }
    }
  }
  #toother{
    display: flex;
    width: 100%;
    span{
      flex: 1;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #999999;
    }
    .left{
      text-align: left;
    }
    .right{
      text-align: right;
    }
  }
}

</style>
