import tomessage from '../../tools/tomessage'
import { ElMessage } from 'element-plus'

// this.$message.close()
const setmessage = {
  User: (data) => {
    // tomessage.str = data
    const status = tomessage.fromvalidate('User', data)
    // console.log(status)
    if (status.data.type === 'success') {
      // gome.success(status.data)
    } else {
      // gome.error(status.data)
    }
  },
  Phone: (data) => {
    const status = tomessage.fromvalidate('Phone', data)
    // console.log(status)
    if (status.data.type === 'success') {
      // gome.success(status.data)
    } else {
      // gome.error(status.data)
    }
  },
  Password: (data) => {
    const status = tomessage.fromvalidate('Password', data)
    // console.log(status)
    if (status.data.type === 'success') {
      // gome.success(status.data)
    } else {
      // gome.error(status.data)
    }
  },
  ConfirmPwd: (data, olddata) => {
    const status = tomessage.fromvalidate('Confirmpwd', { data, olddata })
    // console.log(status)
    if (status.data.type === 'success') {
      // gome.success(status.data)
    } else {
      // gome.error(status.data)
    }
  },
  AuthCode: (data) => {
    const status = tomessage.fromvalidate('AuthCode', data)
    // console.log(status)
    if (status.data.type === 'success') {
      // gome.success(status.data)
    } else {
      gome.error(status.data)
    }
  }
}

const gome = {
  success: (obj) => {
    ElMessage(
      obj
    )
  },
  error: (obj) => {
    ElMessage(
      obj
    )
  }
}

export default {
  setmessage
  // gome
}
